import * as React from "react"
import { graphql } from 'gatsby'

// Hooks
import { useTranslation } from 'gatsby-plugin-react-i18next';

// COmponents
import { Page } from 'components/core/layouts/base';
import { Hero } from 'components/grant/hero';
import { Footer } from 'components/core/footer';
import { GrantList, HectiqCanHelp } from 'components/grant/content';
import { ContactWithCurve } from 'components/core/contact';

function IndexPage({location}){
  const {t} = useTranslation("grant");
  return <Page dark={false} location={location} title={t("metadata.title")} description={t("metadata.description")}>
              <Hero/>
          <GrantList/>
          <HectiqCanHelp/>
          <ContactWithCurve/>
          <Footer/>
          </Page>
}

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;